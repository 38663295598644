import request from '@/core/services/api.services.request'

export function getAllorder(params) {
  return request({
    url: '/order/status',
    method: 'get',
    params: params
  })
}


export function deliveryOrder() {
  return request({
    url: '/order/delivery/create',
    method: 'post',
  })
}

export function getCorder(order_id) {
  return request({
    url: '/order/delivery/'+order_id,
    method: 'get',
  })
}

export function getbBackorder(backorder_id) {
  return request({
    url: 'back/order/'+backorder_id,
    method: 'get',
  })
}